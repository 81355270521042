import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {ApiModulesService} from '../../all-modules/api-modules.service';
import{ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-newpassword',
  templateUrl: './newpassword.component.html',
  styleUrls: ['./newpassword.component.css']
})
export class NewpasswordComponent implements OnInit {
  public form: FormGroup;
  loading: boolean;
  errors:any;
  token:any;
  newpassword=true;
  isvalidconfirmpassword = false;
passwordRegex:any= /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?=.*\d).{8,}$/;
  constructor(private _authService: AuthService,private activatedRoute: ActivatedRoute,private apiModuleService: ApiModulesService, private router: Router, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    window.FreshworksWidget('hide');

      this.activatedRoute.paramMap.subscribe(params => {
          this.token = params.get('token');
      });
      this.form = this.formBuilder.group({
          email: ['', [Validators.required]],
          password: ['', [Validators.required,Validators.pattern(this.passwordRegex)]],
          confirmPassword: ['', [Validators.required]],

      });
  }

  resetPassword() {
      if (!this.form.valid) {
          this.form.markAllAsTouched();
          return;
      }
      if (this.form.value.password != this.form.value.confirmPassword) {
          this.isvalidconfirmpassword = true;
      }
      else {
          this.isvalidconfirmpassword = false;

          const params: any = {};
          params.token =this.token;
          params.password = this.form.get('password').value;
          params.email = this.form.get('email').value;
          params.password_confirmation = this.form.get('confirmPassword').value;
          this._authService.passwordChange(params,'api/new/reset/password')
              .subscribe((res: any) => {

                  this.router.navigate(['login']);
              },(err:any)=>{

                  this.errors='something went wrong TryAgain!....'
              });
      }
  }

  passwordVisible(){
    this.newpassword=!this.newpassword
setTimeout(()=>{
    this.newpassword=!this.newpassword;
},500)
}
}
