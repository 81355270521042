import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css'],
})
export class ForgotComponent implements OnInit {
    public form: FormGroup;
    errors:any;
    success:any;
  constructor(private _authService: AuthService, private formBuilder: FormBuilder) { }
  ngOnInit() {
    window.FreshworksWidget('hide');

      this.form = this.formBuilder.group({
          email: ['', [Validators.required]],
      });
  }
  submit() {
      this.success=null;
      this.errors=null;
      const params: any = {};
      params.email = this.form.get('email').value;
      this._authService.passwordChange(params,'api/forgot/password')
          .subscribe((res: any) => {
              this.success='Link sent to Your Email'
          },(err:any)=>{
              this.errors='something went wrong TryAgain!....'
          });
  }
}
