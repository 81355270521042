import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthenticationGuard} from './core/auth/authentication.guard';
import {EmployeeAuthenticationGuard} from './core/auth/employee-authentication.guard';
import{ResetpasswordComponent} from"./login/reset/resetpassword.component";
import { ForgotComponent } from './login/forgot/forgot.component';
import { NewpasswordComponent } from './login/newpassword/newpassword.component';

const routes: Routes = [ {
    path: 'login',
    loadChildren: () => import(`./login/login.module`).then((m) => m.LoginModule)
}, {
    path: 'freshworks',
    loadChildren: () => import(`./freshworks/freshworks.module`).then((m) => m.FreshworksModule), canActivate: [AuthenticationGuard]
}, {
    path: 'error',
    loadChildren: () => import(`./errorpages/errorpages.module`).then((m) => m.ErrorpagesModule)
}, {
    path: '',
    loadChildren: () => import(`./all-modules/all-modules.module`).then((m) => m.AllModulesModule),
    canActivate: [AuthenticationGuard]
},{path:'reset/password',component:ResetpasswordComponent},
    {path:'forgot/password',component:ForgotComponent},
    {path:'new/password/:token',component:NewpasswordComponent},{ path: '**', redirectTo: '/login' }];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
