import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import {BehaviorSubject} from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { AllModulesData } from 'src/assets/all-modules-data/all-modules-data';
import { id } from 'src/assets/all-modules-data/id';
import { param } from 'jquery';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiModulesService {
  // Chats
  value=[];
    public dataSource  = new BehaviorSubject(this.value)
    auth_data= this.dataSource.asObservable();

  language:any='';
  public languageSource  = new BehaviorSubject(this.language)
  languages= this.languageSource.asObservable();
  groups = {
    active: '',
    total: ['general', 'video responsive survey', '500rs', 'warehouse'],
  };
  members = {
    active: 'Mike Litorus',
    total: [
      { name: 'John Doe', count: 3 },
      { name: 'Richard Miles', count: 0 },
      { name: 'John Smith', count: 7 },
      { name: 'Mike Litorus', count: 9 },
    ],
  };

  // Api Methods for All modules
  private domain=environment.apiUrl;
  public apiurl;
  // Headers Setup

  headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .set('Authorization','Bearer'+  localStorage.getItem('token')
    );

  httpOptions = {
    headers: this.headers,

  };
  constructor(private http: HttpClient) {}

  // Handling Errors
  private handleError(error: any) {
    return throwError(error);
  }
  sendData(data)
    {
        this.dataSource.next(data);
    }
sendLanguageData(lang){
  this.languageSource.next(lang)
}
  // Get Method Api
  get(type:any): Observable<any>  {
    this.apiurl = this.domain+`api/${type}`;
    return this.http
      .get<any>(this.apiurl, this.httpOptions)
      .pipe(tap(), catchError(this.handleError));  }

  // Post Method Api
  add(params:any, type:any): Observable<any> {
    this.apiurl = this.domain+`api/${type}`;
    return this.http
      .post<any>(this.apiurl,params, this.httpOptions)
      .pipe(tap(), catchError(this.handleError));
  }

  // Edit Method Api
edit(params:any,type:any): Observable<any>{
  this.apiurl=this.domain+`api/${type}`;
  return this.http
  .post<any>(this.apiurl,params, this.httpOptions)
      .pipe(tap(), catchError(this.handleError));

}
  // Update Method Api
  update(user: any, type): Observable<any> {
    this.apiurl = this.domain+`api/${type}`;
    const url = `${this.apiurl}/${user.id}`;
    return this.http.put<any>(url, user, this.httpOptions).pipe(
      map(() => user),
      catchError(this.handleError)
    );
  }

  // List Method Api
  list(method: any,params:any): Observable<any> {
    this.apiurl = this.domain+`api/${method}`;
    return this.http
      .post<any>(this.apiurl, params, this.httpOptions)
      .pipe(tap(), catchError(this.handleError));

  }


  // Delete Method Api
  delete(id: id, type): Observable<id> {
    this.apiurl = this.domain+`api/${type}`;
    const url = `${this.apiurl}/${id}`;
    return this.http
      .delete<id>(url, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

}
