import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private service:AuthService,private route:Router){

  }
  canActivate(){
    if(this.service.loggedIn() && this.service.firstLogin() ){
    return true;
    }else{
      this.route.navigate(['login']);
      return false;
    }
  }

}
