import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { WebStorage } from 'src/app/core/storage/web.storage';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    loginUserData = {};
    public form: FormGroup;
    loading: boolean;
    errors: [];
    password = true;
    is_first_login: any;
    constructor(private _authService: AuthService, private router: Router, private formBuilder: FormBuilder) {

    }

    ngOnInit() {

        window.FreshworksWidget('hide');

        this.form = this.formBuilder.group({
            email: ["", [Validators.required]],
            password: ["", [Validators.required]],

        });
    }

    loginUser() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return
        }
        var params: any = {};
        params.email = this.form.get('email').value;
        params.password = this.form.get('password').value;
        this._authService.loginUser(params)
            .subscribe((res: any) => {
                localStorage.removeItem('token');
                // Store the access token in the localstorage
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('user_type', res.data.user.user_type);
                this.is_first_login = res.data.user.is_first_login;

                switch (localStorage.getItem('user_type')) {
                    case 'admin':
                        localStorage.setItem('is_first_login', 'true');
                        this.router.navigate(['dashboard/admin']);
                        break;
                    case 'client':
                        localStorage.setItem('is_first_login', 'true');
                        localStorage.setItem('client_id', res.data.user.client_id);
                        this.router.navigate(['dashboard/admin']);
                        break;
                    case 'company':
                        localStorage.setItem('is_first_login', 'true');
                        localStorage.setItem('client_id', res.data.user.client_id);
                        localStorage.setItem('company_id', res.data.user.company_id);
                        this.router.navigate(['dashboard/admin']);
                        break;
                    case 'employee':
                        if (this.is_first_login == 0) {
                            localStorage.setItem('is_first_login', 'true');
                            localStorage.setItem('client_id', res.data.user.client_id);
                            localStorage.setItem('company_id', res.data.user.company_id);
                            localStorage.setItem('employee_id', res.data.user.employee_id);
                            this.router.navigate(['employee/ess/IND/home']);
                        } else {
                            this.router.navigate(['reset/password']);
                        }

                        break;
                }

                // this.loading = false;
                // Navigate to home page


            }, (err: any) => {
                this.errors = err.status==422 && err.error.errors.email !=undefined?err.error.errors.email:err.error.errors.message


                setTimeout(() => {
                    this.errors = [];
                }, 3000)
            });

    }
    passwordVisible() {
        this.password = !this.password
        setTimeout(() => {
            this.password = !this.password;
        }, 500)
    }
}
