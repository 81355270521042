import { EventEmitter, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private count = 0;
  private spinner$ = new BehaviorSubject<string>('');

  constructor(private toastr: ToastrService) { }

  getSpinnerObserver(): Observable<string> {
    return this.spinner$.asObservable();
  }

  requestStarted() {
    if (++this.count === 1) {
      this.spinner$.next('start');
    }
  }

  requestEnded() {
    if (this.count === 0 || --this.count === 0) {
      this.spinner$.next('stop');
    }
  }

  resetSpinner() {
    this.count = 0;
    this.spinner$.next('stop');
  }
  dataemitterr= new EventEmitter<any>();
  //   dataemitterr= new Subject<any>();
     raiseDataEmitterEvent(data:any){
         this.dataemitterr.next(data);
     }
     toasterError(err){
      this.raiseDataEmitterEvent('off')
      let errorsData = err.error.data;
      let firstKey = Object.keys(errorsData)[0];  // Getting the first key
      let errors = errorsData[firstKey];      // Getting the value for the first key

            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
      this.toastr.error(errors, 'TryAgain');
    }
  leaveapplyemitterr= new EventEmitter<any>();
  //   dataemitterr= new Subject<any>();
     compOffEmitterEvent(data:any){
         this.leaveapplyemitterr.next(data);
     }

}
