import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {ApiModulesService} from '../../all-modules/api-modules.service';

@Component({
    selector:'app-reset-password',
    templateUrl:'./resetpassword.component.html'
})
export  class ResetpasswordComponent implements OnInit {
    public form: FormGroup;
    loading: boolean;
    oldpassword=true;
    password=true;
    errors: [];
    isvalidconfirmpassword = false;
passwordRegex:any= /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?=.*\d).{8,}$/;
    constructor(private apiModuleService: ApiModulesService, private router: Router, private formBuilder: FormBuilder) {

    }

    ngOnInit() {
        window.FreshworksWidget('hide');

        this.form = this.formBuilder.group({
            old_password: ['', [Validators.required]],
            password: ['', [Validators.required,Validators.pattern(this.passwordRegex)]],
            confirmPassword: ['', [Validators.required]],

        });
    }

    resetPassword() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        if (this.form.value.password != this.form.value.confirmPassword) {
            this.isvalidconfirmpassword = true;
        }
        else {
            this.isvalidconfirmpassword = false;

            const params: any = {};
            params.old_password = this.form.get('old_password').value;
            params.password = this.form.get('password').value;
            params.password_confirmation = this.form.get('confirmPassword').value;
            this.apiModuleService.list('reset/password', params)
                .subscribe((res: any) => {
                    params.token=localStorage.getItem('token');
                    this.apiModuleService.list('logout',params).subscribe((data:any)=>{
                        window.localStorage.removeItem('token');
                        window.localStorage.removeItem('is_first_login');
                        window.localStorage.clear();
                        this.router.navigateByUrl('/login');
                    })
                }, (err: any) => {
                    this.errors = [];
                    // This error can be internal or invalid credentials
                    // You need to customize this based on the error.status c
                    setTimeout(() => {
                        this.errors = [];
                    }, 3000)
                              });
        }
    }

    oldpasswordVisible(){
        this.oldpassword=!this.oldpassword
        setTimeout(()=>{
            this.oldpassword=!this.oldpassword;
        },500)
    }

    passwordVisible(){
        this.password=!this.password
    setTimeout(()=>{
        this.password=!this.password;
    },500)
}
}
