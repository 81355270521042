// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .login-icon{
  margin-left: 168px;
  margin-top: -20px;
}
.login-header{
  float:right;
  padding-right: 510px;
  padding-top: 145px;
  width:fit-content;
}
.account-box {
	background-color: #fff;
	border: 1px solid #ededed;
	border-radius: 4px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	margin: 0 auto;
	overflow: hidden;
	width: 485px;

}
.account-wrapper {
	padding: 40px;
} */
`, "",{"version":3,"sources":["webpack://./src/app/login/login/login.component.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;GAsBG","sourcesContent":["/* .login-icon{\r\n  margin-left: 168px;\r\n  margin-top: -20px;\r\n}\r\n.login-header{\r\n  float:right;\r\n  padding-right: 510px;\r\n  padding-top: 145px;\r\n  width:fit-content;\r\n}\r\n.account-box {\r\n\tbackground-color: #fff;\r\n\tborder: 1px solid #ededed;\r\n\tborder-radius: 4px;\r\n\tbox-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);\r\n\tmargin: 0 auto;\r\n\toverflow: hidden;\r\n\twidth: 485px;\r\n\r\n}\r\n.account-wrapper {\r\n\tpadding: 40px;\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
