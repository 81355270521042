import { Component } from '@angular/core';
import { ApiModulesService } from '../all-modules/api-modules.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-freshworks',
  templateUrl: './freshworks.component.html',
  styleUrls: ['./freshworks.component.css']
})
export class FreshworksComponent {
  constructor(private apimodules:ApiModulesService,private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      console.log(params)
      this.apimodules.add(params,'freshworks/login').subscribe((data)=>{
        window.location.href=data.url
      })
    });
}
}
