import { Renderer2, OnInit, Inject,Component, AfterViewInit } from '@angular/core';
import { NavigationEnd, Event, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

declare const $: any;
declare global {
  interface Window {
    FreshworksWidget: (command: string, ...args: any[]) => void;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,AfterViewInit  {
  title = 'smarthr';


  url: string;
  user!: { firstName: string; lastName: string; };
  welcome!: string;
  usernameLabel!: string;
  passwordLabel!: string;
  constructor(private router: Router,private _renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document
  ){
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/');
        this.url = url[1];
        if (
          event.url == '/' ||
          event.url == '/login' ||
          event.url == '/login/register' ||
          event.url == '/login/forgot' ||
          event.url == '/login/otp' ||
          event.url == '/login/lockscreen'
        ) {
          document.querySelector('body').classList.add('account-page');
        } else {
          document.querySelector('body').classList.remove('account-page');
        }
        if (
          event.url == '/error/error404' ||
          event.url == '/error/error500'
        ) {
          document.querySelector('body').classList.add('error-page');
        } else {
          document.querySelector('body').classList.remove('error-page');
        }
      }
    });

  }
  ngOnInit() {

    $(document).on('click', '#toggle_btn', () => {
      if ($('body').hasClass('mini-sidebar')) {
        $('body').removeClass('mini-sidebar');
        $('.subdrop + ul').slideDown();
      } else {
        $('body').addClass('mini-sidebar');
        $('.subdrop + ul').slideUp();
      }
      return false;
    });

    $(document).on('mouseover', (e) => {
      e.stopPropagation();
      if ($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
        const targ = $(e.target).closest('.sidebar').length;
        if (targ) {
          $('body').addClass('expand-menu');
          $('.subdrop + ul').slideDown();
        } else {
          $('body').removeClass('expand-menu');
          $('.subdrop + ul').slideUp();
        }
        return false;
      }
    });

    $('body').append('<div class="sidebar-overlay"></div>');
    $(document).on('click', '#mobile_btn', function() {
      var $wrapper = $('.main-wrapper');
      $wrapper.toggleClass('slide-nav');
      $('.sidebar-overlay').toggleClass('opened');
      $('html').addClass('menu-opened');
      $('#task_window').removeClass('opened');
      return false;
    });

    $(".sidebar-overlay").on("click", function () {
      var $wrapper = $('.main-wrapper');
        $('html').removeClass('menu-opened');
        $(this).removeClass('opened');
        $wrapper.removeClass('slide--nav');
        $('.sidebar-overlay').removeClass('opened');
        $('#task_window').removeClass('opened');
    });




      // let freshworksChartScript = this._renderer2.createElement('script');
      // freshworksChartScript.src = 'https://widget.freshworks.com/widgets/153000000546.js';

      // freshworksChartScript.onload = () => {
      //     var freshworksChartElement = document.getElementById('freshworks-container');
      //     function checkForElement() {

      //         if (freshworksChartElement) {

      //   var launcherFrame = document.getElementById('launcher-frame');
      //   launcherFrame.addEventListener('click', function() {
      //     // Your event handling code here
      // });

      //             var currentRoute = window.location.pathname;
      //             if( currentRoute=='/login' || this.clientId!=3){
      //                 freshworksChartElement.style.display = 'none';

      //             }else{
      //                 freshworksChartElement.style.display = 'flex';
      //             }
      //         } else {
      //             setTimeout(checkForElement, 1000);
      //         }
      //     }
      //     checkForElement();

      // };

      // freshworksChartScript.onerror = (error) => {
      // };

      // this._renderer2.appendChild(this._document.body, freshworksChartScript);


  }
  ngAfterViewInit() {
    var currentRoute = window.location.pathname;
    if( currentRoute=='/login'|| currentRoute=='/reset/password' || currentRoute=='/forgot/password' || currentRoute=='/new/password/:token'){
      window.FreshworksWidget('hide');
    }else{
      window.FreshworksWidget('hide');
    }

    // Call FreshworksWidget('hide') here
  }


}
