import {Component, OnInit, ChangeDetectorRef, Renderer2, Inject} from '@angular/core';
import { SpinnerService } from './spinner.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner-component.html',
  styleUrls: ['./spinner-component.scss']
})
export class SpinnerComponent implements OnInit {
  datas: any;

  clientId=localStorage.getItem('client_id');


  constructor(private spinnerService: SpinnerService, private cdRef: ChangeDetectorRef,private _renderer2: Renderer2,
              @Inject(DOCUMENT) private _document: Document) {

  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.spinnerService.dataemitterr.subscribe((data)=>{
        this.datas=data;

        // var currentRoute = window.location.pathname;
        // if( currentRoute=='/login'|| currentRoute=='/reset/password' || currentRoute=='/forgot/password' || currentRoute=='/new/password/:token'){
        //   window.FreshworksWidget('hide');
        // }else{
        //   window.FreshworksWidget('show');
        // }

    });
    }
  }

