import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private inject: Injector) {}
  intercept(req, next) {
    let authservice=this.inject.get(AuthService);
    let token = req.clone({
      setHeaders: {
        Authorization: `Bearer  ${authservice.getToken()}`
      }
    });
    // const token = localStorage.token;
    // if (token) {
    //   req = req.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    // }
    return next.handle(token);
  }
}
